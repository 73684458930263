import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import Missing from '../views/404.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'MultiScrollPortfolio',
        meta: {
            title: '嘉晟科技'
        },
        component: () =>
            import ('../views/MultiScrollPortfolio.vue')
    },
    {
        path: '/portfolio-details',
        name: 'PortfolioDetails',
        meta: {
            title: 'Rainfo || Portfolio Details'
        },
        component: () =>
            import ('../views/PortfolioDetails.vue')
    },
    {
        path: '/service-details',
        name: 'ServiceDetails',
        meta: {
            title: 'Rainfo || Service Details'
        },
        component: () =>
            import ('../views/ServiceDetails.vue')
    },
    {
        path: '/blog-details',
        name: 'BlogDetails',
        meta: {
            title: 'Rainfo || Blog Details'
        },
        component: () =>
            import ('../views/BlogDetails.vue')
    },
    {
        path: '/contact',
        name: 'Contact',
        meta: {
            title: 'Rainfo || Contact'
        },
        component: () =>
            import ('../views/Contact.vue')
    },
    {
        path: '/about-us',
        name: 'AboutUs',
        meta: {
            title: 'Rainfo || AboutUs'
        },
        component: () =>
            import ('../views/AboutUs.vue')
    },
    {
        path: '/awards',
        name: 'Awards',
        meta: {
            title: 'Rainfo || Awards'
        },
        component: () =>
            import ('../views/Awards.vue')
    },
    {
        path: '/blog',
        name: '',
        meta: {
            title: '战略地图'
        },
        component: () =>
            import ('../views/Blog.vue')
    },
    {
        path: '/portfolio',
        name: 'Portfolio',
        meta: {
            title: 'Rainfo || Portfolio'
        },
        component: () =>
            import ('../views/Portfolio.vue')
    },
    {
        path: '/service',
        name: 'Service',
        meta: {
            title: 'Rainfo || Service'
        },
        component: () =>
            import ('../views/Service.vue')
    },
    {
        path: '*',
        component: Missing,
        meta: {
            title: 'Rainfo || 404'
        },
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0

        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }

        return goTo(scrollTo)
    },
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    next()
})


export default router
